import axios from '../lib/axios';
import { getTestExecutionByTestCycle } from './test-executions';

export function getTestCycles() {
    return axios.get('/api/test-management/test-cycles')
        .then(response => response.data)
        .then(cycles => {
            const sortedCycles = cycles.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            return Promise.all(sortedCycles.map(cycle => {
                return getTestExecutionByTestCycle({ testCycleId: cycle._id }).then(execution => {
                    return { 
                        ...cycle,
                        execution
                    }
                });
            }));
        });
}

export function deleteTestCycle(id) {
    return axios.delete(`/api/test-management/test-cycles/${id}`)
        .then(response => response.data);
}

export function createTestCycle({ name, description, testCases }) {
    return axios.post(`/api/test-management/test-cycles`, {
        name, description
    })
        .then(response => response.data._id)
        .then((testCycleId) => {
            return Promise.all(testCases.map((testCase, index) => {
                return axios.post(`/api/test-management/test-cycles-test-cases`, {
                    test_cycle_id: testCycleId,
                    test_case_id: testCase._id,
                    order: index,
                })
            }))
        });
}

export function updateTestCycle({ id, name, description, originalTestCases, testCases }) {
    return axios.put(`/api/test-management/test-cycles/${id}`, {
        name,
        description
    })
        .then(response => response.data._id)
        .then((testCycleId) => {
            return Promise.all(originalTestCases.map(testCase => {
                return axios.delete(`/api/test-management/test-cycles/${id}/test-cycles-test-cases/${testCase._id}`)
            })).then(() => {
                return Promise.all(testCases.map((testCase, index) => {
                    return axios.post(`/api/test-management/test-cycles-test-cases`, {
                        test_cycle_id: testCycleId,
                        test_case_id: testCase._id,
                        order: index,
                    })
                }))
            });
        });
}

export function getTestCycle({ id, allExecutions = false }) {
    return axios.get(`/api/test-management/test-cycles/${id}`)
        .then(response => {
            const testCycle = response.data;

            return axios.get(`/api/test-management/test-cycles/${id}/test-cycles-test-cases`)
                .then(responseTestCases => {
                   return {
                       ...testCycle,
                       testCases: responseTestCases.data,
                   }
                });
        }).then(testCycle => {
            return getTestExecutionByTestCycle({ testCycleId: testCycle._id, all: allExecutions }).then(execution => {
                return {
                    ...testCycle,
                    execution,
                };
            });
        });
}
