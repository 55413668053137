/**
 * third party dependencies
 */
import React, { useEffect, useRef, useState } from 'react';
import { defaultContext, useQueryClient } from '@tanstack/react-query'
import FolderTree from 'react-folder-tree';
import Skeleton from 'react-loading-skeleton';

/**
 * labqube components
 */
import { Message, PageHeader, Table, Button, Input, Loading, LastUpdateBy } from '@labqube/components';

/**
 * services
 */
import {deleteTestCase, getTestCases} from '../../services/test-cases';
import { createTestSection, updateTestSection, deleteTestSection } from '../../services/test-sections';
import { createTestSuite, updateTestSuite, deleteTestSuite } from '../../services/test-suites';

/**
 * hooks
 */
import useTestSuites from '../../hooks/useTestSuites';

/**
 * local components
 */
import { SpotlightProvider } from '../../components/spotlight/SpotlightContext';
import SpotlightStep from '../../components/spotlight/SpotlightStep';
import { CustomEditIcon, CustomFileIcon, CustomFolderIcon, CustomDeleteIcon } from './folder-tree/custom-icons';
import CategoryModal from './category-modal';
import ConfirmationModal from './confirmation-modal';

import './list.css';
import 'react-folder-tree/dist/style.css';
import { Link } from 'react-router-dom';

const List = () => {
    const [testCases, setTestCases] = useState([]);
    const [filteredTestCases, setFilteredTestCases] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorState, setErrorState] = useState(false);
    const [filter, setFilter] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const [isSectionModalOpen, setIsSectionModalOpen] = useState(false);
    const [selectedTestSuiteId, setSelectedTestSuiteId] = useState(null);
    const [nameSection, setNameSection] = useState('');
    const [descriptionSection, setDescriptionSection] = useState('');

    const [selectedEditName, setSelectedEditName] = useState('');
    const [selectedEditDescription, setSelectedEditDescription] = useState('');
    const [selectedEditId, setSelectedEditId] = useState('');
    const [selectedCategoryType, setSelectedCategoryType] = useState('');

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedDeleteName, setSelectedDeleteName] = useState('');
    const [selectedDeleteSuiteId, setSelectedDeleteSuiteId] = useState('');
    const [selectedDeleteSectionId, setSelectedDeleteSectionId] = useState('');

    const [isWide, setIsWide] = useState(false);

    const folderTreeRef = useRef()
    const searchTestCasesef = useRef()
    const letsStartRef = useRef()

    const queryClient = useQueryClient()

    const { data: testSuites, isLoading: isLoadingTestSuites } = useTestSuites()

    function refresh() {
        getTestCases().then(testCases => {
            setTestCases(testCases);
            setLoading(false);
        }).catch(e => {
            setLoading(false);
            setErrorState(true);
        });
    }

    useEffect(() => {
        setFilteredTestCases(
            testCases.filter(t => {
                return t.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
            }).map(item => {
                return {
                    id: item._id,
                    content: {
                        name: (
                            <Link 
                                to={`/test-cases/${item._id}`}
                                style={{ color: 'black', wordBreak: 'break-word' }}
                            >
                                {item.name}
                            </Link>
                        ),
                        description: <span style={{ color: 'black', wordBreak: 'break-word' }}>{item.description || '-'}</span>,
                        'last update by': <LastUpdateBy accountId={item.user_account_id} queryClientContext={defaultContext} />,
                        actions: (
                            <div>
                                <Link 
                                    to={`/test-cases/${item._id}/edit`}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                    <i className="fa-solid fa-pen-to-square clickable"></i>
                                </Link>
                                &nbsp;
                                <i className="fa-solid fa-trash clickable" onClick={() => deleteItem(item)}></i>
                            </div>
                        )
                    },
                }
            })
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testCases, filter]);

    useEffect(() => {
        const updateWhiteSpace = () => {
          const container = document.querySelector(".folder-wrapper");
          if (container) {
            const parentWidth = container.parentElement.offsetWidth;
            const editableWidth = container.offsetWidth;
    
            setIsWide(editableWidth / parentWidth >= 0.3);
          }
        };
    
        setTimeout(() => {
            updateWhiteSpace();
        }, 1000);
    
        window.addEventListener("resize", updateWhiteSpace);
    
        return () => {
          window.removeEventListener("resize", updateWhiteSpace);
        };
      }, [testSuites]);

    function deleteItem(item) {
        const result = window.confirm('Do you want to delete this test case and its contents?');

        if (result) {
            deleteTestCase(item._id).then(() => {
                refresh();
            }).catch((e) => {
                console.log(e);
                // TODO https://labqube.atlassian.net/browse/COM-4
            });
        }
    }

    const handleSuiteAndSectionUpsertAction = () => {
        const isSuite = selectedCategoryType === 'suite';
        const isEdit = selectedEditId
    
        // Validation for required fields
        if (isSuite && !name) {
            setErrorState('Please provide a name for the Test Suite.');
            return;
        }
    
        if (!isSuite && !nameSection) {
            setErrorState('Please provide a name for the Test Section.');
            return;
        }
    
        const data = {
            name: isSuite ? name : nameSection,
            description: isSuite ? description : descriptionSection,
            ...(isSuite ? {} : { test_suites_id: selectedTestSuiteId }),
        };
    
        const action = isEdit
            ? isSuite
                ? updateTestSuite(selectedEditId, data)
                : updateTestSection(selectedTestSuiteId, selectedEditId, data)
            : isSuite
                ? createTestSuite(data)
                : createTestSection(data);
    
        action
            .then(() => {
                queryClient.invalidateQueries({ queryKey: ['test-suites'] });
    
                if (isSuite) {
                    setName('');
                    setDescription('');
                    setIsModalOpen(false);
                } else {
                    setNameSection('');
                    setDescriptionSection('');                    
                    setIsSectionModalOpen(false);
                }
    
                setIsSectionModalOpen(false);

                setErrorState('');
            })
            .catch((error) => {
                console.error(`Error ${isEdit ? 'updating' : 'creating'} ${selectedCategoryType}:`, error);
                setErrorState(`Failed to ${isEdit ? 'update' : 'create'} ${selectedCategoryType}. Please try again.`);
            });
    };
    
    const handleOpenModal = () => setIsDeleteModalOpen(true);
    const handleCloseModal = () => setIsDeleteModalOpen(false);

    const handleConfirmDelete = (selection) => {
        if (selectedCategoryType === 'suite') {
            const target = {
                testSuiteId: selection.testSuiteId
            };

            deleteTestSuite(selectedDeleteSuiteId, target)
                .then(() => {
                    queryClient.invalidateQueries({ queryKey: ['test-suites'] });
                    handleCloseModal();
                })
                .catch((error) => {
                    console.error('Error deleting suite:', error);
                });
        } else if (selectedCategoryType === 'section') {
            const target = {
                testSuiteId: selection.testSuiteId,
                testSectionId: selection.testSectionId,
            };

            deleteTestSection(selectedDeleteSuiteId, selectedDeleteSectionId, target)
                .then(() => {
                    queryClient.invalidateQueries({ queryKey: ['test-suites'] });
                    handleCloseModal();
                })
                .catch((error) => {
                    console.error('Error deleting section:', error);
                });
        }
    };   

    useEffect(() => {
        refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading || isLoadingTestSuites) {
        return (
            <Loading size='large'>
                <p>
                    Loading test cases...
                </p>
            </Loading>
        )
    }

    if (errorState) {
        return (
            <div style={{ margin: 41 }}>
                <Message
                    title={'Oh no! Something went wrong'}
                    description={'Try reloading the page or contact support if the error persists'}
                />
            </div>
        )
    }

    return (
        <SpotlightProvider localStorageKey='test-cases-dashboard-onboarding'>
            <div style={{ padding: 41, paddingTop: 0 }}>
                <PageHeader
                    title={'Test cases'}
                    breadcrumbs={[{
                        href: '',
                        text: 'Test cases'
                    }]}
                    action={
                        <div style={{ display: 'flex', alignItems: 'center' }} ref={searchTestCasesef}>
                            <Input 
                                style={{ marginRight: 10, minWidth: 400 }} 
                                placeholder='Search test cases'
                                value={filter}
                                onChange={(e) => {
                                    setFilter(e.target.value);
                                }}
                            />
                            <SpotlightStep 
                                order={1}
                                position='left'
                                target={searchTestCasesef} 
                                title={'Search for test cases'}
                                nextButton={<Button type='primary'>Next</Button>}
                                offset={150}
                            >
                                <p className='spotlight-title'>Search test cases</p>
                                <p className='spotlight-text'>Type the name of a test cases to quickly filter the list</p>
                            </SpotlightStep>
                            {
                                testCases.length !== 0 && (
                                    <>  
                                        <Link to={'/test-cases/new'} style={{ whiteSpace: 'nowrap' }}>
                                            <Button type={'primary'} style={{height: 37}}>
                                                Add test case
                                            </Button>
                                        </Link>
                                        <SpotlightStep 
                                            order={3}
                                            position='bottom'
                                            target={searchTestCasesef} 
                                            title={'Add test cases'}
                                            dismissable={true}
                                            offset={100}
                                        >
                                            <p className='spotlight-title'>Create test cases easily</p>
                                            <p className='spotlight-text'>Click on the "Add test case" button to continue adding test cases</p>
                                        </SpotlightStep>
                                    </>
                                )
                            }
                        </div>
                    }
                />

                <div style={{ display: 'flex', marginTop: 20 }}>
                    {/* Sección del árbol de carpetas */}
                    <div className={`folder-wrapper ${isWide ? "wide" : ""}`} style={{ width: 'fit-content', paddingRight: 50, maxWidth: '30%', whiteSpace: 'nowrap' }} ref={folderTreeRef}>
                        <p style={{ fontWeight: '500' }}>Suites and sections</p>
                        {loading ? (
                            <Skeleton height={500} />
                        ) : (
                            <FolderTree
                                data={{
                                    name: "Suites",
                                    children: testSuites,
                                }}
                                onNameClick={({defaultOnClick}) => {
                                    defaultOnClick();
                                }}
                                showCheckbox={false}
                                iconComponents={{
                                    AddFolderIcon: (node) => {
                                        return (
                                            <CustomFolderIcon 
                                                node={node}
                                                onClick={(node) => {
                                                    setSelectedEditName('')
                                                    setSelectedEditDescription('')
                                                    setSelectedEditId('')
                                                    setSelectedCategoryType('suite')
                                                    setIsModalOpen(true)
                                                }}
                                            />
                                        )
                                    },
                                    AddFileIcon: (node) => {
                                        return (
                                            <CustomFileIcon 
                                                node={node} 
                                                onClick={(node) => {
                                                    setSelectedEditName('')
                                                    setSelectedEditDescription('')
                                                    setSelectedEditId('')
                                                    setSelectedCategoryType('section')
                                                    setSelectedTestSuiteId(node.nodeData.id)
                                                    setIsSectionModalOpen(true) 
                                                }} 
                                            />
                                        )
                                    },
                                    /**
                                     * right now these icons are not used, once we implement the edit and delete functionality
                                     * we can use real icons. for now these will be hidden
                                     */
                                    EditIcon: (node) => {
                                        return (
                                            <CustomEditIcon 
                                                node={node} 
                                                onClick={(node, type) => {
                                                    setSelectedEditId(node.nodeData.id)
                                                    setSelectedCategoryType(type)
                                                    
                                                    if (type === 'suite') {
                                                        const suite = testSuites.find(suite => suite.id === node.nodeData.id);
                                                        setSelectedEditName(suite.name);
                                                        setSelectedEditDescription(suite.description);
                                                        setIsModalOpen(true);
                                                    } else {
                                                        const flatSections = testSuites.reduce((suites, currentSuite) => {
                                                            return [...suites, ...currentSuite.children];
                                                        }, [])

                                                        const section = flatSections.find(section => section.id === node.nodeData.id);
                                                        setSelectedTestSuiteId(section.testSuiteId);
                                                        setSelectedEditName(section.name);
                                                        setSelectedEditDescription(section.description);
                                                        setIsSectionModalOpen(true);
                                                    }
                                                }} 
                                            />
                                        )
                                    },
                                    DeleteIcon: (node) => {
                                        return (
                                            <CustomDeleteIcon
                                                node={node}
                                                onClick={(id, type, name) => {
                                                    handleOpenModal();
                                                    setSelectedCategoryType(type);
                                                    if (type === 'suite') {
                                                        setSelectedDeleteSuiteId(node.nodeData.id);
                                                    } else {
                                                        setSelectedDeleteSuiteId(node.nodeData.testSuiteId);
                                                        setSelectedDeleteSectionId(node.nodeData.id);
                                                    }
                                                    setSelectedDeleteName(name);
                                                }}
                                            />
                                        );
                                    },                                    
                                }}
                                nodeRenderer={(node, defaultRenderer) => (
                                    <div 
                                        style={{
                                            whiteSpace: 'normal',
                                            wordBreak: 'break-word'
                                        }}
                                    >
                                        {defaultRenderer(node)}
                                    </div>
                                )}
                            />
                        )}
                        <SpotlightStep
                            order={1}
                            position="bottom"
                            target={folderTreeRef}
                            title={"Folder tree step"}
                            nextButton={<Button type="primary">Next</Button>}
                            offset={-150}
                        >
                            <p className="spotlight-title">Suites and sections</p>
                            <p className="spotlight-text">
                                Manage suites and sections here. <br /> Click on an item to open the available options.
                            </p>
                        </SpotlightStep>
                    </div>

                    {/* Sección de la lista de test cases */}
                    <div style={{ maxWidth: '90%', width: '-webkit-fill-available' }} ref={letsStartRef}>
                        {
                            filteredTestCases.length
                                ? <></>
                                : (
                                    <SpotlightStep 
                                        order={3}
                                        position='bottom'
                                        target={letsStartRef} 
                                        title={'Create your first test case!'}
                                        dismissable={true}
                                        offset={100}
                                    >
                                        <p className='spotlight-title'>Create test cases easily</p>
                                        <p className='spotlight-text'>Click on the "Add test case" button to create your first test case <br/><br/> Once you have test cases created, you will find this button in at the page header level available at any time</p>
                                    </SpotlightStep>
                                )
                        }
                        {loading ? (
                            <Skeleton count={5} height={40} />
                        ) : (
                            !filteredTestCases.length ? (
                                <Message
                                    title={'No test cases to show'}
                                    description={'Try creating a new one!'}
                                    actionComponent={
                                        <Link to={'/test-cases/new'}>
                                            <Button type="primary">
                                                Add test case
                                            </Button>
                                        </Link>
                                    }
                                />
                            ) : (
                                <Table
                                    headers={['Name', 'Description', 'Last update by', 'Actions']}
                                    columnsWidth={['30%', '30%', '30%', '10%']}
                                    items={filteredTestCases}
                                    pagination={true}
                                />
                            )
                        )}
                    </div>
                </div>

                {
                    isModalOpen && (
                        <CategoryModal 
                            title={selectedEditId ? 'Edit Test Suite' : 'Create Test Suite'}
                            name={selectedEditName}
                            description={selectedEditDescription}
                            onChange={({ name, description }) => {
                                setName(name)
                                setDescription(description)
                            }}
                            onClose={() => setIsModalOpen(false)}
                            onCreate={handleSuiteAndSectionUpsertAction}
                            isEditing={!!selectedEditId}
                        />
                    )
                }

                {
                    isSectionModalOpen && (
                        <CategoryModal 
                        title={selectedEditId ? 'Edit Test Section' : 'Create Test Section'}
                            name={selectedEditName}
                            description={selectedEditDescription}
                            onChange={({ name, description }) => {
                                setNameSection(name)
                                setDescriptionSection(description)
                            }}
                            onClose={() => setIsSectionModalOpen(false)}
                            onCreate={handleSuiteAndSectionUpsertAction}
                            isEditing={!!selectedEditId}
                        />
                    )
                }
                {
                    isDeleteModalOpen && (
                        <ConfirmationModal
                            title="Confirm Deletion"
                            message={`Are you sure you want to delete the ${selectedCategoryType === 'suite' ? 'suite' : 'section'} "${selectedDeleteName}"?`}
                            onConfirm={handleConfirmDelete}
                            onClose={handleCloseModal}
                            isDeletingSuite={selectedCategoryType === 'suite'}
                            excludeCurrentId={selectedCategoryType === 'suite' ? selectedDeleteSuiteId : selectedDeleteSectionId}
                        />
                    )
                }
            </div>
        </SpotlightProvider>
    )
}

export default List;