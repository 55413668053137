import './custom-icons.css';

export const CustomFileIcon = ({ node, onClick }) => {
    return node.nodeData._id !== 0 ? (
        <i 
            className="fa-regular fa-file icon-hover"
            onClick={() => onClick(node)}
        />
    ) : null
};

export const CustomFolderIcon = ({ node, onClick }) => {
    return node.nodeData._id === 0 ? (
        <i
            className="fa-regular fa-folder icon-hover"
            onClick={() => onClick(node)}
        />
    ) : null;
};

export const CustomEditIcon = ({ node, onClick }) => {
    return node.nodeData._id !== 0 ? (
        <i
            className="fa fa-pencil-square-o icon-hover"
            onClick={() => {
                const type = node.nodeData.path.length === 1 ? 'suite' : 'section';
                return onClick(node, type)
            }}
        />
    ) : null;
};

export const CustomDeleteIcon = ({ node, onClick }) => {
    return node.nodeData._id !== 0 ? (
        <i
            className="fa-regular fa-trash-can icon-hover"
            onClick={() => {
                const { _id: id, name, path } = node.nodeData;
                const type = path.length === 1 ? 'suite' : 'section';

                return onClick(id, type, name)
            }}
        />
    ) : null;
};

