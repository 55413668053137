import axios from '../lib/axios';

export function getTestSections(testSuiteId) {
    return axios.get(`/api/test-management/test-suites/${testSuiteId}/test-sections`)
        .then(response => response.data);
}

export function createTestSection(data) {
    return axios.post('api/test-management/test-sections', data)
        .then(response => response.data);
}

export function updateTestSection(testSuiteId, id, data) {
    return axios.put(`/api/test-management/test-suites/${testSuiteId}/test-sections/${id}`, data)
        .then(response => response.data);
}

export function deleteTestSection(testSuiteId, id, target) {
    return axios.delete(`/api/test-management/test-suites/${testSuiteId}/test-sections/${id}`, { data: { target } })    
        .then(response => response.data);
}