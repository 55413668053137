import React, { useState } from 'react';
import { Modal, Button, Spinner } from '@labqube/components';
import TestSuiteSelector from './test-suite-selector';

const ConfirmationModal = ({ title, message, onConfirm, onClose, isDeletingSuite, excludeCurrentId }) => {
    const [selection, setSelection] = useState({ testSuiteId: '', testSectionId: '' });
    const [isLoading, setIsLoading] = useState(false);

    const handleSelectionChange = (newSelection) => {
        setSelection(newSelection);
    };

    const handleConfirm = async () => {
        setIsLoading(true);
        try {
            await onConfirm(selection);
            onClose();
        } catch (error) {
            console.error('Error during confirmation:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            visible={true}
            title={title}
            footer={
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button 
                        type="default" 
                        style={{ marginRight: '10px' }} 
                        onClick={onClose}
                        disabled={isLoading}
                    >
                        Cancel
                    </Button>
                    <Button 
                        type="primary" 
                        disabled={!selection.testSuiteId || (!isDeletingSuite && !selection.testSectionId)} 
                        onClick={handleConfirm}
                    >
                        {isLoading ? <Spinner /> : 'Delete'}
                    </Button>
                </div>
            }
        >
            <div style={{ textAlign: 'center' }}>
                <p>{message}</p>
                <p>
                    Select the {isDeletingSuite ? 'suite' : 'suite and section'} where the test cases will be transferred to.
                </p>
                <br />
                <TestSuiteSelector
                    testSuiteId={selection.testSuiteId}
                    testSectionId={selection.testSectionId}
                    handleSelectionChange={handleSelectionChange}
                    hideSection={isDeletingSuite}
                    excludeCurrentId={excludeCurrentId}
                />
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
