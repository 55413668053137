import React, { useContext, useState, useEffect } from 'react';
import { useQueryClient, defaultContext } from '@tanstack/react-query';

import { Message, Button } from '@labqube/components';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from '@labqube/react-draft-wysiwyg';

import TestExecutionContext from '../../../context/test-execution';
import { updateTestExecutionTestCaseStatus } from '../../../services/test-executions';
import { STATUS } from '../../../constants';
import useTestCases from '../../../hooks/useTestCases';

import Steps from '../../test-cases/steps'

import './index.css';
import styled from 'styled-components';
import '@labqube/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const icons = {
    'pending': 'fa-solid fa-hourglass-start',
    'success': 'fa-solid fa-check',
    'failure': 'fa-solid fa-xmark',
    'skip': 'fa-solid fa-forward',
};

const Wrapper = styled.div`
    text-align: center;
`;

const SummaryContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: auto;
`;

const SummaryItem = styled.div`
    font-size: 30px;
    text-align: center;
`;

const Success = styled.p`
    color: green;
`;

const Failure = styled.p`
    color:red;
`;

const Skip = styled.p`
    color: grey;
`;

const TestRun = () => {
    const queryClient = useQueryClient();

    const { selectedTestCaseRun, testExecution } = useContext(TestExecutionContext);
    const { data: testCase }  = useTestCases({ id: selectedTestCaseRun?.test_case_id_details?._id }, defaultContext);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        if (selectedTestCaseRun?.test_case_id_details?.preconditions) {
            try {
                const rawContent = JSON.parse(selectedTestCaseRun?.test_case_id_details?.preconditions);
                const contentState = convertFromRaw(rawContent);
                setEditorState(EditorState.createWithContent(contentState));
            } catch (e) {
                console.error("Error parsing preconditions:", e);
            }
        }
    }, [selectedTestCaseRun]);

    function updateStatus(status) {
        return updateTestExecutionTestCaseStatus({
            testExecutionId: testExecution._id,
            testCaseId: selectedTestCaseRun._id,
            status,
        }).then(() => {
            queryClient.invalidateQueries({ queryKey: ['test-execution', testExecution._id] });
        });
    }

    if (!selectedTestCaseRun) {
        if (testExecution?.status === STATUS.TEST_EXECUTION_RUNNING) {
            return <Message
                title={'No test case selected'}
                description={'Please select one from the list on the left'}
            />
        }

        const success = testExecution.testCases.filter(t => t.status === STATUS.TEST_CASE_SUCCESS);
        const failure = testExecution.testCases.filter(t => t.status === STATUS.TEST_CASE_FAILURE);
        const skip = testExecution.testCases.filter(t => t.status === STATUS.TEST_CASE_SKIP);

        return (
            <Wrapper>
                <p> The test execution was composed by <strong>{testExecution?.testCases?.length} test cases</strong>. Here is a summary of how it went. </p>
                <SummaryContainer>
                    <SummaryItem>
                        <Success>{success.length} ({(success.length / testExecution.testCases.length * 100).toFixed(2)}%)</Success>
                        <Success>Success</Success>
                    </SummaryItem>
                    <SummaryItem>
                        <Failure>{failure.length} ({(failure.length / testExecution.testCases.length * 100).toFixed(2)}%)</Failure>
                        <Failure>Failure</Failure>
                    </SummaryItem>
                    <SummaryItem>
                        <Skip>{skip.length} ({(skip.length / testExecution.testCases.length * 100).toFixed(2)}%)</Skip>
                        <Skip>Skip</Skip>
                    </SummaryItem>
                </SummaryContainer>
                <p> You can click the items on the left to see details for each one. </p>
            </Wrapper>
        )
    }

    function Actions() {
        if (testExecution?.status !== STATUS.TEST_EXECUTION_RUNNING) {
            return (
                <div>
                    <span className={`result-${selectedTestCaseRun?.status}`}>
                        <i className={icons[selectedTestCaseRun?.status]}></i> Marked as <strong>{ selectedTestCaseRun.status }</strong>
                    </span>
                </div>
            )
        }

        if (selectedTestCaseRun?.status === STATUS.TEST_CASE_PENDING) {
            return (
                <div>
                    <Button type="default" style={{ marginRight: 5 }} onClick={() => updateStatus(STATUS.TEST_CASE_SKIP)}>
                        <i class="fa-solid fa-forward"></i> Skip
                    </Button>
                    <Button type="default" style={{ marginRight: 5 }} onClick={() => updateStatus(STATUS.TEST_CASE_FAILURE)}>
                        <i class="fa-solid fa-xmark"></i> Failure
                    </Button>
                    <Button type="primary" onClick={() => updateStatus(STATUS.TEST_CASE_SUCCESS)}>
                        <i class="fa-solid fa-check"></i> Success
                    </Button>
                </div>
            )
        } else {
            return (
                <div>
                    <Button type="default" onClick={() => updateStatus(STATUS.TEST_CASE_PENDING)}>
                        <i class="fa-solid fa-pen"></i> Change status
                    </Button>
                </div>
            )
        }
    }

    function Title() {
        return (
            <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                <div style={{ width: '60%', wordBreak: 'break-word' }}>
                    <p style={{ fontWeight: 'bold' }}>{ testCase?.name }</p>
                    <p>{ testCase?.description || 'No description provided.' }</p>
                </div>
                <Actions />
            </div>
        )
    }

    function Preconditions() {
        if (selectedTestCaseRun?.test_case_id_details.preconditions) {
            const rawContent = JSON.parse(selectedTestCaseRun.test_case_id_details.preconditions);
            const hasValidPreconditions = rawContent?.blocks?.some(block => block.text.trim() !== "");
    
            if (hasValidPreconditions) {
                return (
                    <div style={{ marginTop: 50 }}>
                        <p style={{ fontWeight: 'bold' }}>Preconditions</p>
                        <Editor
                            editorState={editorState}
                            readOnly={true}
                            toolbarHidden={true}
                            wrapperClassName='demo-wrapper'
                            editorClassName='demo-preconditions'
                        />
                    </div>
                )
            }
        }
    
        return (
            <div style={{ marginTop: 50 }}>
                <p style={{ fontWeight: 'bold' }}>Preconditions</p>
                <span>
                    No preconditions assigned to this test case
                </span>
            </div>
        )
    }

    function Step() {
        if (testCase?.steps?.length) {
            return <Steps steps={testCase.steps} />
        }

        return (
            <div style={{ marginTop: 50 }}>
                <p style={{ fontWeight: 'bold' }}>Steps</p>
                <span>
                    No steps assigned to this test case
                </span>
            </div>
        )
    }

    return (
        <div>
            <Title />
            <Preconditions />
            <Step />
        </div>
    )
}

export default TestRun;
