import React from 'react';

/**
 * company-managed dependencies
 */
import { Select, Loading } from '@labqube/components';

/**
 * hooks
 */
import useTestSuites from '../../hooks/useTestSuites';

const TestSuiteSelector = ({ testSuiteId, testSectionId, handleSelectionChange, hideSection, excludeCurrentId }) => {
    const { data: testSuites, isLoading: isLoadingTestSuites } = useTestSuites();

    if (isLoadingTestSuites) {
        return (
            <Loading size='large'>
                <p>
                    Loading test suites...
                </p>
            </Loading>
        )
    }

    const handleTestSuiteChange = (selected) => {
        handleSelectionChange({ testSuiteId: selected.value, testSectionId: '' });
    };

    const handleTestSectionChange = (selected) => {
        handleSelectionChange({ testSuiteId, testSectionId: selected.value });
    };

    const filteredTestSuites = testSuites.filter(suite => suite.id !== excludeCurrentId);

    return (
        <div>
            <div style={{ width: '60%', margin: 'auto' }}>
                <p>Test Suite<span className="required">*</span></p>
                <Select 
                    placeholder={
                        testSuiteId
                            ? filteredTestSuites.find((suite) => suite.id === testSuiteId)?.name
                            : "Select a test suite"
                    }
                    options={
                        filteredTestSuites.map(testSuite => ({
                                label: testSuite.name,
                                value: testSuite.id,
                        }))
                    }
                    onChange={handleTestSuiteChange}
                    style={{ width: '100%' }}
                />
            </div>
            {!hideSection && (
                <div style={{ width: '60%', margin: 'auto', marginTop: 20 }}>
                    <p>Test Section<span className="required">*</span></p>
                    <Select 
                        key={`select-section-${testSuiteId}`}
                        placeholder={
                            testSectionId
                                ? testSuites
                                    .find((suite) => suite.id === testSuiteId)
                                    ?.children.find((section) => section.id === testSectionId)?.name
                                : "Select a test section"
                        }
                        options={
                            [...testSuites].find(testSuite => testSuite.id === testSuiteId)?.children?.filter(section => section.id !== excludeCurrentId).map(testSection => {
                                return {
                                    label: testSection.name,
                                    value: testSection.id,
                                }
                            }) ?? []
                        }
                        onChange={handleTestSectionChange}
                        style={{ width: '100%' }}
                    />
                </div>
            )}
        </div>
    );
};

export default TestSuiteSelector