import axios from '../lib/axios';

export function getTestSuites() {
    return axios.get('/api/test-management/test-suites')
        .then(response => response.data);
}

export function createTestSuite(data) {
    return axios.post('api/test-management/test-suites', data)
        .then(response => response.data);
}

export function updateTestSuite(id, data) {
    return axios.put(`/api/test-management/test-suites/${id}`, data)
        .then(response => response.data);
}

export function deleteTestSuite(id, target) {
    return axios.delete(`/api/test-management/test-suites/${id}`, { data: { target } })
        .then(response => response.data);
}