/**
 * third party dependencies
 */
import React, { useEffect, useState } from 'react';

/**
 * labqube components
 */
import { Button, Input, Modal, TextArea } from '@labqube/components';


const CategoryModal = ({ title, name, description, onChange, onClose, onCreate, isEditing }) => {
    const [suiteName, setSuiteName] = useState(name || '')
    const [suiteDescription, setSuiteDescription] = useState(description || '')

    useEffect(() => {
        onChange({
            name: suiteName,
            description: suiteDescription,
        })
    }, [onChange, suiteName, suiteDescription])

    return (
        <Modal
            visible={true}
            title={title}
            footer={
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="default" style={{ marginRight: '10px' }} onClick={onClose}>Cancel</Button>
                    <Button type="primary" disabled={!suiteName} onClick={onCreate}>{isEditing ? 'Save' : 'Create'}</Button>
                </div>
            }
        >
            <div>
                <p>Name<span className={'required'}>*</span></p>
                <Input
                    type="text"
                    value={suiteName}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 100) {
                            setSuiteName(value);
                        }
                    }}
                    placeholder="Enter the name (max 100 characters)"
                />
                <p style={{ fontSize: '0.8rem', color: 'gray' }}>{suiteName.length}/100</p>
            </div>
            <div>
                <p>Description:</p>
                <TextArea
                    value={suiteDescription}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 500) {
                            setSuiteDescription(value);
                        }
                    }}
                    placeholder="Enter the description (max 500 characters)"
                />
                <p style={{ fontSize: '0.8rem', color: 'gray' }}>{suiteDescription.length}/500</p>
            </div>
        </Modal>
    )
}

export default CategoryModal
