import axios from '../lib/axios';

export function getTestCaseSteps(testCaseId) {
    if (!testCaseId) {
        return null;
    }

    return axios.get(`/api/test-management/test-cases/${testCaseId}/test-case-steps`)
        .then(response => {
            const testCaseSteps = response.data.sort((a, b) => a.order - b.order);

            return Promise.all(testCaseSteps.map(step => {
                return getAttachments({ testCaseStepId: step._id })
                    .then(attachments => {
                       return {
                           ...step,
                           actionAttachments: attachments.filter(a => a.stage === 'action'),
                           expectationAttachments: attachments.filter(a => a.stage === 'result'),
                       };
                    });
            }));
        });
}

export function createTestCaseStep({ action, expectation, test_case_id, order }) {
    return axios.post(
        '/api/test-management/test-case-steps',
        {
            action,
            expectation,
            test_case_id,
            order,
        }
    ).then(response => response.data);
}

export function deleteTestCaseStep(testCaseId, stepId) {
    return axios.delete(`/api/test-management/test-cases/${testCaseId}/test-case-steps/${stepId}`)
        .then(response => response.data);
}

export function addAttachment({testCaseStepId, content, thumb_content, filename, media_type, stage}) {
    return axios.post(
        `/api/test-management/attachments`,
        {
            test_case_step_id: testCaseStepId,
            filename,
            content,
            thumb_content,
            media_type,
            stage,
        }
    ).then(response => response.data);
}

export function getAttachments({testCaseStepId}) {
    return axios.get(`/api/test-management/test-case-steps/${testCaseStepId}/attachments`)
        .then(response => response.data);
}