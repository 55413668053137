import axios from '../lib/axios';

export function getTestCases({ filterBy = undefined } = {}) {
    if (filterBy) {
        return axios.get(`/api/test-management/test-cases?filter_by=${filterBy}`)
            .then(response => response.data);
    }

    return axios.get('/api/test-management/test-cases')
        .then(response => response.data);
}

export function getTestCase(id) {
    if (!id) {
        return null
    }

    return axios.get(`/api/test-management/test-cases/${id}`)
        .then(response => response.data);
}

export function deleteTestCase(id) {
    return axios.delete(`/api/test-management/test-cases/${id}`)
        .then(response => response.data);
}


export function createTestCase({ name, description, preconditions, steps, test_suite_id, test_section_id }) {
    return axios.post(
        '/api/test-management/test-cases',
        {
            name,
            description,
            preconditions,
            steps,
            test_suite_id,
            test_section_id
        }
    );
}

export function updateTestCase({id, name, description, preconditions, steps, test_suite_id, test_section_id }) {
    return axios.put(
        `/api/test-management/test-cases/${id}`,
        {
            name,
            description,
            preconditions,
            steps,
            test_suite_id,
            test_section_id
        }
    );
}
