import { useQuery } from '@tanstack/react-query'

import { getTestCase } from '../services/test-cases';
import { getTestCaseSteps } from '../services/test-case-steps';

const useTestCases = ({ id }, context) => {
    const { isLoading, data } = useQuery({ 
        context,
        queryKey: ['test-cases', id], 
        queryFn: () => {
            return Promise.all([
                getTestCase(id),
                getTestCaseSteps(id)
            ]).then(([testCase, steps]) => {
                if (!testCase || !steps) {
                    return null;
                }

                return {
                    ...testCase,
                    steps,
                }
            });
        } 
    })

    if (!id) {
        return {
            isLoading: false,
            data: null,
        };
    }

    return {
        isLoading,
        data,
    }
}

export default useTestCases;