/**
 * third party dependencies
 */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';

/**
 * company-managed dependencies
 */
import { PageHeader, Button, Spinner } from '@labqube/components';
import { Editor } from '@labqube/react-draft-wysiwyg';

/**
 * components
 */
import Name from "./name";
import Steps from "./steps";
import TestSuiteSelector from '../test-suite-selector';

/**
 * lib
 */
import { dataURLtoFile } from "../../../lib/util";

/**
 * services
 */
import {createTestCase, getTestCase, updateTestCase} from "../../../services/test-cases";
import {
    getAttachments,
    getTestCaseSteps
} from "../../../services/test-case-steps";

/**
 * css
 */
import '@labqube/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.css';

function toFile(attachment) {
    const file = dataURLtoFile(attachment.content, attachment.filename);
    return {
        name: attachment.filename,
        percent: 100,
        response: file,
        status: 'done',
        type: attachment.media_type,
        thumbUrl: attachment.thumb_content,
        url: attachment.thumb_content,
        originFileObj: file,
    }
}


const Wizard = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [preconditions, setPreconditions] = useState(() => EditorState.createEmpty());
    const [isCreating, setIsCreating] = useState(false);
    const [testSuiteId, setTestSuiteId] = useState('');
    const [testSectionId, setTestSectionId] = useState('');

    const [steps, setSteps] = useState({});

    const navigate = useNavigate();
    const { id: testCaseId } = useParams();

    useEffect(() => {
        if (testCaseId) {
            Promise.all([
                getTestCase(testCaseId),
                getTestCaseSteps(testCaseId).then(steps => steps.sort((a, b) => a.order - b.order)),
            ])
            .then(([testCase, testCaseSteps]) => {
                setName(testCase.name);
                setDescription(testCase.description);
    
                if (testCase.preconditions) {
                    try {
                        const rawContent = JSON.parse(testCase.preconditions);
                        setPreconditions(EditorState.createWithContent(convertFromRaw(rawContent)));
                    } catch (e) {
                        console.error("Error parsing preconditions:", e);
                        setPreconditions(EditorState.createEmpty());
                    }
                }

                setTestSuiteId(testCase.test_suite_id || '');
                setTestSectionId(testCase.test_section_id || '');
    
                const steps = {};
                Promise.all(testCaseSteps.map((step, index) => {
                    return getAttachments({ testCaseStepId: step._id }).then(attachments => {
                        steps[index] = {
                            id: index,
                            action: step.action
                                ? EditorState.createWithContent(convertFromRaw(JSON.parse(step.action)))
                                : EditorState.createEmpty(),
                            expectation: step.expectation
                                ? EditorState.createWithContent(convertFromRaw(JSON.parse(step.expectation)))
                                : EditorState.createEmpty(),
                            actionAttachments: attachments.filter(a => a.stage === 'action').map(toFile),
                            resultAttachments: attachments.filter(a => a.stage === 'result').map(toFile),
                            editing: { action: true, expectation: true },
                        };
                    });
                })).then(() => {
                    setSteps(steps);
                });
            });
        }
    }, [testCaseId]);   

    const handleSelectionChange = ({ testSuiteId, testSectionId }) => {
        setTestSuiteId(testSuiteId);
        setTestSectionId(testSectionId);
    };    

    function addStep({action, expectation}) {
        let max = -1;

        if (Object.keys(steps).length > 0) {
            max = Math.max(...Object.keys(steps).map(Number));
        }

        setSteps({
            ...steps,
            [max + 1]: {
                id: max + 1,
                action,
                expectation,
                actionAttachments: [],
                resultAttachments: [],
                editing: {
                    action: true,
                    expectation: true,
                },
            }
        });
    }

    function updateStep({id, action, expectation, editing, actionAttachments, resultAttachments}) {
        setSteps({
            ...steps,
            [id]: {
                id,
                action,
                expectation,
                actionAttachments,
                resultAttachments,
                editing,
            }
        });
    }

    function deleteStep(id) {
        const localSteps = {...steps};
        delete localSteps[id];

        setSteps(localSteps);
    }

    async function saveTestCase() {
        setIsCreating(true);
    
        const stepsToUpload = await Promise.all(Object.keys(steps)
            .filter((stepKey) => {
                const step = steps[stepKey];
                return step.action || step.expectation; // Filtrar pasos vacíos
            })
            .map(async (stepKey, index) => {
                const step = steps[stepKey];
    
                return {
                    action: JSON.stringify(convertToRaw(step.action.getCurrentContent())),
                    expectation: JSON.stringify(convertToRaw(step.expectation.getCurrentContent())),
                    order: index,
                };
            })
        );
    
        try {
            await updateTestCase({
                id: testCaseId,
                name,
                description,
                preconditions: JSON.stringify(convertToRaw(preconditions.getCurrentContent())),
                steps: stepsToUpload,
                test_suite_id: testSuiteId,
                test_section_id: testSectionId,
            });
            
            navigate('/');
        } catch (e) {
            // TODO https://labqube.atlassian.net/browse/COM-4
            console.error(e)
        }
    }    

    async function createNewTestCase() {
        setIsCreating(true);

        const stepsToUpload = await Promise.all(Object.keys(steps)
            .filter((stepKey) => {
                const step = steps[stepKey];
                return step.action || step.expectation; // Filtrar pasos vacíos
            })
            .map(async (stepKey, index) => {
                const step = steps[stepKey];
    
                return {
                    action: JSON.stringify(convertToRaw(step.action.getCurrentContent())),
                    expectation: JSON.stringify(convertToRaw(step.expectation.getCurrentContent())),
                    order: index,
                };
            })
        );

        try {

            await createTestCase({
                name, 
                description, 
                preconditions: JSON.stringify(convertToRaw(preconditions.getCurrentContent())),
                steps: stepsToUpload,
                test_suite_id: testSuiteId,
                test_section_id: testSectionId,
            });

            navigate('/');
        } catch (e) {
            // TODO https://labqube.atlassian.net/browse/COM-4
        }
    }

    function areStepsValid() {
        return Object.keys(steps).some(key => {
            const step = steps[key];
            return step.action && step.expectation;
        });
    }

    const handleEditorChange = (state) => {
        setPreconditions(state);
    };

    return (
        <div style={{ padding: 41, paddingTop: 0 }}>
            <PageHeader
                title={testCaseId ? 'Edit Test Case' : 'New Test Case'}
                breadcrumbs={[
                    {
                        href: <Link to={'/'}>Test cases</Link>,
                    },
                    {
                        href: '',
                        text: testCaseId ? 'Edit test case' : 'New test case'
                    }
                ]}
                action={
                    isCreating ? (
                        <Button type="primary">
                            <Spinner stroke="white" size="medium" />
                        </Button>
                    ) : (
                        <Button
                            type={'primary'}
                            style={{height: 35}}
                            disabled={!name.trim() || !testSuiteId || !testSectionId || !areStepsValid()}
                            onClick={() => testCaseId ? saveTestCase() : createNewTestCase()}
                        >
                            {testCaseId ? 'Save test case' : 'Create test case'}
                        </Button>
                    )
                }
            />
            <div>
                <div>
                    <h2 className='section-title basic'>Basic information</h2>
                    <Name
                        name={name}
                        setName={setName}
                        description={description}
                        setDescription={setDescription}
                    />
                </div>
                <div 
                    style={{ 
                        marginBottom: 20, 
                        marginTop: 50, 
                        paddingTop: 20,
                        paddingBottom: 20, 
                        borderTop: '1px solid #efefef', 
                        borderBottom: '1px solid #efefef',
                    }}
                >
                    <h2 className='section-title'>Test suite selection</h2>
                    <TestSuiteSelector 
                        testSuiteId={testSuiteId}
                        testSectionId={testSectionId}
                        handleSelectionChange={handleSelectionChange}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: 20, paddingBottom: 20, borderBottom: '1px solid #efefef', }}>
                    <h2 className='section-title'>Preconditions</h2>
                    <div style={{ width: '60%', margin: 'auto' }}>
                        <Editor
                            editorState={preconditions}
                            wrapperClassName='demo-wrapper'
                            editorClassName='demo-editor'
                            onEditorStateChange={handleEditorChange}
                            toolbar={{
                                options: ['inline', 'list', 'textAlign', 'link', 'remove', 'history', 'image'],
                                inline: {
                                    options: ['bold', 'italic', 'underline'],
                                },
                                list: {
                                    options: ['unordered', 'ordered'],
                                },
                                textAlign: {
                                    options: ['left', 'center', 'right', 'justify'],
                                },
                                image: {
                                    previewImage: true,
                                    uploadCallback: (file) => {
                                        return new Promise((resolve, reject) => {
                                            const reader = new FileReader();
                                            reader.readAsDataURL(file);
                                        reader.onload = () => {
                                          const dataURL = reader.result;
                                          const truncatedDataURL = dataURL.substring(10, 30) + "..."; // set the maximum length of the truncated string
                                          resolve({ data: { link: dataURL } , link : { url : truncatedDataURL} });
                                        };
                                        reader.onerror = (error) => {
                                          reject(error);
                                        };
                                        });
                                    },
                                    alt: { present: true, mandatory: false }
                                }
                            }}
                        />
                    </div>
                </div>
                <h2 className='section-title'>Steps</h2>
                <div style={{ width: '60%', margin: 'auto' }}>
                    <Steps
                        steps={steps}
                        addStep={addStep}
                        updateStep={updateStep}
                        deleteStep={deleteStep}
                    />
                </div>
            </div>
        </div>
    );
}

export default Wizard;
